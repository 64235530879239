<template>
    <div>
        <div class="card-media ratio" :style="{paddingBottom: padding+'%'}">
            <gmap-map class="fill-parent" ref="mapRef"
                      :center="center"
                      :zoom="17"
                      map-type-id="terrain"
                      :options="{
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        gestureHandling: 'cooperative',
                        rotateControl: false,
                        fullscreenControl: false
                      }"
                      style="position: absolute;">

                <gmap-marker :position="position"
                             :clickable="true"
                             :draggable="true"
                             @drag="updateCircle"
                             @dragend="updatePosition"/>

                <gmap-circle :radius="100"
                             v-if="!isGlobal"
                             :center="circle"
                             :options="{fillColor: '#689eee', strokeColor: '#5188d8'}"/>

                <gmap-circle v-for="(img, i) in images"
                             v-if="!img.is_global"
                             :key="i"
                             :radius="100"
                             :center="img.position"
                             :options="{fillColor: '#e1e1e1', strokeColor: '#a5a5a5'}"/>
            </gmap-map>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="md-down:col-12 col">
                    <mega-input :label="$t('lat')" v-model="_lat"/>
                </div>
                <div class="md-down:col-12 col">
                    <mega-input :label="$t('lng')" v-model="_lng"/>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            lat: Number,
            lng: Number,
            isGlobal: Boolean,
            getLocation: Boolean,
            ratio: String,
            images: Array
        },
        data() {
            return {
                center: {
                    lat: this.lat,
                    lng: this.lng
                },
                position: {
                    lat: this.lat,
                    lng: this.lng
                },
                circle: {
                    lat: this.lat,
                    lng: this.lng
                }
            }
        },
        computed: {
            _lat: {
                get() { return this.position.lat },
                set(value) {
                    this.position.lat = parseFloat(value);
                    this.$emit('update', this.position)
                }
            },
            _lng: {
                get() { return this.position.lng },
                set(value) {
                    this.position.lng = parseFloat(value);
                    this.$emit('update', this.position)
                }
            },
            padding() {
                let r = this.ratio ? this.ratio.toString().split('x') : [4, 3];

                return (r[1] / r[0]) * 100;
            }
        },
        mounted() {
            if(this.getLocation) this.getPosition();
        },
        methods: {

            getPosition() {
                let geo_success = (position) => {
                        this.updatePosition({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }).updateCircle().updateCenter();
                    },
                    geo_error = () => {
                        this.updatePosition({lat: 59.95812333259337, lng: 30.299955456375073}).updateCircle().updateCenter();
                        console.log('Geolocation error');
                    };

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(geo_success, geo_error);
                }
                else geo_error();
            },

            updatePosition(location) {

                if(location.latLng && location.latLng){
                    this._lat = location.latLng.lat();
                    this._lng = location.latLng.lng();
                }
                else if(location.lat && location.lng) {
                    this._lat = location.lat;
                    this._lng = location.lng;
                }
                else {
                    this.center = {
                        lat: this._lat,
                        lng: this._lng
                    }
                }

                return this;
            },

            updateCircle(location) {

                if(location && location.latLng && location.latLng){
                    this.circle.lat = location.latLng.lat();
                    this.circle.lng = location.latLng.lng();
                }
                else if(location && location.lat && location.lng) {
                    this.circle.lat = location.lat;
                    this.circle.lng = location.lng;
                }
                else {
                    this.circle = {
                        lat: this._lat,
                        lng: this._lng
                    }
                }

                return this;
            },

            updateCenter(location) {

                if(location && location.lat && location.lng) {
                    this.center = location;
                }
                else {
                    this.center = {
                        lat: this._lat,
                        lng: this._lng
                    }
                }

                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo(this.center)
                })

                return this;
            }
        }
    }
</script>
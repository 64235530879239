<template>
    <mega-modal class="modal-sm"
                :active="modal"
                @onClose="close">

        <div v-if="modal">
            <mega-header :title="$t('ref_title')" class="bg-lighter">
                <span class="text-muted">{{ $t('ref_sub_title') }}</span>
            </mega-header>

            <div class="row p-3">
                <div class="sm-down:col-12 md-up:col-6">
                    <div class="card-body">
                        <mega-drop-zone @change="renderFile" :type="['image/jpeg', 'image/png']">
                            <mega-image class="image bg-contain" ratio="16x9" :src="preview"/>
                        </mega-drop-zone>
                    </div>

                    <div class="card-body">

                        <mega-input :label="$t('new_model_name')" v-model="name"/>

                        <mega-textarea :label="$t('desc')" v-model="description"/>

                    </div>
                </div>

                <div class="sm-down:col-12 md-up:col-6">
                    <div class="card-body">

                        <mega-range class="bg-primary mb-0" :label="$t('ref_pic_width')" v-model="width" :min="10" :max="1000" :step="5"/>
                        <div class="d-flex justify-content-between text-muted">
                            <small>10</small>
                            <small>1000</small>
                        </div>

                        <mega-number :min="10" :max="1000" :step="5" v-model="width" :help="width+'cm'"/>

                        <div class="p-0" v-if="serverSide">
                            <mega-range class="bg-primary mb-0" :label="$t('ref_rec_percent')" v-model="percent" :min="30" :max="80"/>
                            <div class="d-flex justify-content-between text-muted">
                                <small>30</small>
                                <small>80</small>
                            </div>
                            <mega-number class="mb-0 form-white" :min="30" :max="80" :step="5" v-model="percent" :help="percent+'%'"/>
                        </div>

                        <mega-switch class="w-100 mt-3 bg-success" :label="$t('ref_global_loc')"
                                    v-model="is_global"/>
                    </div>
                </div>
                <div class="card-body pt-0 col-12">

                    <map-widget 
                        ratio="16x9"
                        get-location
                        v-if="!is_global"
                        :images="ref_images"
                        @update="updatePosition"
                        :is-global="is_global"
                        :lat="position.lat || 0"
                        :lng="position.lng || 0"/>
					
				</div>

				<div class="card-body col-12">
					<div class="navbar">
						<div class="navbar-group ml-auto">
							<mega-button class="btn-white btn-sm mr-2" @click="close">{{ $t('close') }}</mega-button>
							<mega-button class="btn-success btn-sm" :disabled="!name || !width" @click="submit">{{ $t('model_save') }}</mega-button>
						</div>
					</div>
				</div>
            </div>
        </div>
    </mega-modal>
</template>

<script>
    import MapWidget from './map'
    import axios from 'axios'

    export default {
        props: {
			id: [String, Number],
			mod_id: {
				type: [String,Number],
				required: true
			}
        },
        data() {
            return {
                position: {
                    lat: 59.9598743,
                    lng: 30.2965115
                },
                keys: {},
                modal: false,
                name: '',
                description: '',
                preview: undefined,
                is_global: false,
                serverSide: true,
                file: undefined,
                width: 100,
                percent: 50
            }
        },
        computed: {
            ref_images() {

                let images = this.$parent.ref_images || [];

                return images.map(img => {

                    let position = {lng: img.lng, lat: img.lat};

                    return {position, is_global: img.is_global}
                });
            }
        },
        methods: {

            submit() {

                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("description", this.description);
                formData.append("image", this.file);
                formData.append("server_side", this.serverSide);
                formData.append("idt_model_modif", parseInt(this.mod_id));
                formData.append("is_global", this.is_global);
                formData.append("match_percent", this.percent);
                formData.append("width", this.width);
                formData.append("lng", this.position.lng);
                formData.append("lat", this.position.lat);

                this.$api.v2.post('/refImage', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => response.data['ref_image'])
                    .then((ref_image) => {
                        this.$alert.success('New ref image was added')
                        this.close();
                    })
                    .catch((err) => { 
						if (err.response.status === 400) {
							this.$alert.danger('Image is invalid!');
						} else {
							this.$alert.danger('Oops.. Server error');
						}
					})
            },

            open() {
                document.body.style.overflowY = 'hidden';
                this.modal = true;
            },

            close() {
                document.body.style.overflowY = 'auto';
                this.$emit('close');

                this.position = {
                    lat: 59.9598743,
                    lng: 30.2965115
                };
                this.keys = {};
                this.modal = false;
                this.name = '';
                this.description = '';
                this.preview = undefined;
                this.is_global = false;
                this.file = undefined;
                this.serverSide = false;
                this.width = 100;
                this.percent = 50;
            },

            renderFile(file) {

                this.file = file;

                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onloadend = () => {
                    this.preview = reader.result;
                }
            },

            updatePosition(location) {
                this.position.lat = location.lat;
                this.position.lng = location.lng;
            }
        },
        components: {
            MapWidget
        }
    }
</script>